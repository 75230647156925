<template>
  <div id="about">
    <!-- <div class="hadheight"></div> -->
    <Header />
    <div class="abtbanner" v-if="fbanner">
      <div class="bag">
        <!-- pc -->
        <img :src="fbanner.pc" alt="">
        <!-- mobile -->
        <img :src="fbanner.mb" alt="">
      </div>
      <div class="cont">
        <div class="basecont">
          <div class="font">
            <div class="tit">
              {{banner.subtitle}}
            </div>
            <a href="javascript:;" :data-video-src="banner.video" class="f_video">
              <i><img src="../../assets/images/videicon1.png" alt=""></i>
              <span>{{banner.video_title}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="abtbox-sec1" v-if="banner">
      <!-- <div class="cont base80" v-for="(item,index) in abt1data" :key="index">
        <div class="basecont">
          <div class="abtbox-tit">
            <div class="tit basefont36">
              {{item.title}}
            </div>
            <div class="text" v-html="item.text"></div>
          </div>
        </div>
        <div class="img">
          <img :src="item.url" alt="">
        </div>
      </div> -->
      <div class="cont base80">
        <div class="basecont">
          <div class="abtbox-tit">
            <div class="tit basefont36">
              {{banner.one_title}}
            </div>
            <div class="text" v-html="banner.one_text"></div>
          </div>
        </div>
        <div class="img">
          <img :src="banner.one_image" alt="">
        </div>
      </div>
      <div class="cont base80">
        <div class="basecont">
          <div class="abtbox-tit">
            <div class="tit basefont36">
              {{banner.two_title}}
            </div>
            <div class="text" v-html="banner.two_text"></div>
          </div>
        </div>
        <div class="img">
          <img :src="banner.two_image" alt="">
        </div>
      </div>
      <div class="cont base80">
        <div class="basecont">
          <div class="abtbox-tit">
            <div class="tit basefont36">
              {{banner.three_title}}
            </div>
            <div class="text" v-html="banner.three_text"></div>
          </div>
        </div>
        <div class="img">
          <img :src="banner.three_image" alt="">
        </div>
      </div>
      <div class="cont base80">
        <div class="basecont">
          <div class="abtbox-tit">
            <div class="tit basefont36">
              {{banner.four_title}}
            </div>
            <div class="text" v-html="banner.four_text"></div>
          </div>
        </div>
        <div class="img">
          <img src="../../assets/images/abt1imgf4.jpg" alt="">
          <a href="javascript:;" :style="{left: item.left,top: item.top}" class="item" v-for="(item, index) in banner.four_list" :key="index">
            <div class="font">
              {{item.title}}
            </div>
            <div class="dian">
              <span></span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default{
  name: "about",
  components:{
    Header,
    Footer
  },
  data(){
    return {
      fbanner:{},
      banner:'',
      abt1data: '',
      // abt1data:[
      //   {
      //     title: "将娱乐消费”习惯“融入生活之中。",
      //     text: "<p>Habitos来源于西班牙语中的HÁBITO-习惯，而我们的品牌理念正是把这份“习惯”带进用户的娱乐消费之中。Habitos将在未来发展的几年内，网罗世界各地的中高端改装车配件品牌，以及潮流玩物带给消费者，将娱乐消费”习惯“融入生活之中。</p>",
      //     url: require("../../assets/images/abt1imgf1.jpg")
      //   },
      //   {
      //     title: "将娱乐消费”习惯“融入生活之中。",
      //     text: `<p>
      //               Habitos并不只是一个网购销售平台，更多的是给广大改装车爱好者们，潮玩爱好者们，建立起属于我们自己的社区，一片态度鲜明，
      //           </p>
      //           <p>
      //               自由自在的净土。
      //           </p>
      //           <p>
      //               <br/>
      //           </p>
      //           <p>
      //               随着Habitos的成长与发展，我们公司会奔赴世界各地，为广大车迷们建立起各类选择的桥梁，让全世界优质的改装车品牌都能尽在掌握之中，我们的手机app应用也让全中国的玩车发烧友们齐聚一堂，在这片天地畅所欲言。
      //           </p>`,
      //     url: require("../../assets/images/abt1imgf2.jpg")
      //   },
      //   {
      //     title: "将娱乐消费”习惯“融入生活之中。",
      //     text: `<p>
      //             而我们面对现有混乱的市场，以及各种真假难辨的产品，Habitos的唯一进货渠道介由各大品牌方直接授权经销或代理提供，厂家直达且各类运输的货运单都会在数据库保留，以保证不存在任何假冒或山寨产品，在产品渠道这点上Habitos决不妥协，也不会让广大消费在这点上被迫妥协！这份不妥协的习惯，是Habitos给我们的用户带来的消费体验。
      //         </p>
      //         <p>
      //             <br/>
      //         </p>
      //         <p>
      //             Habitos的两位创始人都是90后，对于改装车文化有着极深的热爱，奔赴海外深造多年，深受国外改装文化影响，但是一切的起点，都来源于儿时的那辆改装四驱车。多年来对这份文化的热忱使两位创始人决定，要将这份文化，心得，快乐与国内改装车爱好者分享，共同享受。
      //         </p>`,
      //     url: require("../../assets/images/abt1imgf3.jpg")
      //   }
      // ]
    }
  },
  created:function(){
    
  },
  mounted:function(){
    
     $(function(){
       // 视频弹窗
        function HiVideoPop(obj) {
            var oVideoBox = $("#hi-video-pop"); //弹窗
            var oClose = oVideoBox.find(".hi-close"); //关闭按钮
            var oVideo = oVideoBox.find("video"); //视频标签
            // 点击显示弹窗，设置视频src，播放视频
            obj.each(function() {
              var _this = $(this);
                _this.click(function() {
                    
                    oVideoBox.stop().fadeIn();
                    oVideo.attr("src", $(this).attr("data-video-src"));
                    oVideo.trigger("play");
                });
            });
            // 点击关闭弹窗，暂停视频播放
            oClose.click(function() {
                oVideoBox.stop().fadeOut();
                oVideo.trigger("pause");
            });
        }
        // 调用
        HiVideoPop($(".f_video")); //参数为点击的对象，必须带有data-video-src属性
     });
    var _this = this;
    function abtBanner(){ //banner
      return _this.$axios.post("/aboutBanner");
    }
    function abtwo(){
      return _this.$axios.post('/api/Column/getAboutInfo');
    }
    this.$axios.all([abtwo()]).then(function(res){
      if(res[0].code==1){
        _this.fbanner = res[0].data.banner;
        _this.banner=res[0].data;
      }
    }).catch(function(error){
      console.log(error);
    });
  },
}

</script>

